@import url('https://fonts.googleapis.com/css2?family=Changa+One&family=Roboto&family=Montserrat:wght@900&display=swap');
@import url("https://api.fontshare.com/v2/css?f[]=cabinet-grotesk@800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@page {
  size: A1;
}

@media print {
  .printHeader {
    max-height: 20rem;
  }
}

html {
  -webkit-print-color-adjust: exact;
}

.pattern {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-size: 19px 19px;
  position: relative;
  z-index: 0;
}